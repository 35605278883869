exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-was-ist-tsx": () => import("./../../../src/pages/about/was-ist.tsx" /* webpackChunkName: "component---src-pages-about-was-ist-tsx" */),
  "component---src-pages-about-wer-ist-tsx": () => import("./../../../src/pages/about/wer-ist.tsx" /* webpackChunkName: "component---src-pages-about-wer-ist-tsx" */),
  "component---src-pages-about-wer-unterstuetzt-tsx": () => import("./../../../src/pages/about/wer-unterstuetzt.tsx" /* webpackChunkName: "component---src-pages-about-wer-unterstuetzt-tsx" */),
  "component---src-pages-data-daten-fakten-tsx": () => import("./../../../src/pages/data/daten-fakten.tsx" /* webpackChunkName: "component---src-pages-data-daten-fakten-tsx" */),
  "component---src-pages-event-submission-tsx": () => import("./../../../src/pages/event-submission.tsx" /* webpackChunkName: "component---src-pages-event-submission-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-linktree-tsx": () => import("./../../../src/pages/linktree.tsx" /* webpackChunkName: "component---src-pages-linktree-tsx" */),
  "component---src-pages-mint-bildungsforschung-themenspezial-artikel-abbau-von-geschlechterunterschieden-tsx": () => import("./../../../src/pages/mint-bildungsforschung/themenspezial-artikel-abbau-von-geschlechterunterschieden.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-themenspezial-artikel-abbau-von-geschlechterunterschieden-tsx" */),
  "component---src-pages-mint-bildungsforschung-themenspezial-artikel-maedchen-fuer-informatik-begeistern-tsx": () => import("./../../../src/pages/mint-bildungsforschung/themenspezial-artikel-maedchen-fuer-informatik-begeistern.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-themenspezial-artikel-maedchen-fuer-informatik-begeistern-tsx" */),
  "component---src-pages-mint-bildungsforschung-themenspezial-artikel-maedchen-wettbewerb-tsx": () => import("./../../../src/pages/mint-bildungsforschung/themenspezial-artikel-maedchen-wettbewerb.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-themenspezial-artikel-maedchen-wettbewerb-tsx" */),
  "component---src-pages-mint-bildungsforschung-themenspezial-interview-fablab-tsx": () => import("./../../../src/pages/mint-bildungsforschung/themenspezial-interview-fablab.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-themenspezial-interview-fablab-tsx" */),
  "component---src-pages-mint-bildungsforschung-themenspezial-interview-ingo-wagner-tsx": () => import("./../../../src/pages/mint-bildungsforschung/themenspezial-interview-ingo-wagner.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-themenspezial-interview-ingo-wagner-tsx" */),
  "component---src-pages-mint-bildungsforschung-tsx": () => import("./../../../src/pages/mint-bildungsforschung.tsx" /* webpackChunkName: "component---src-pages-mint-bildungsforschung-tsx" */),
  "component---src-pages-mintrakete-tsx": () => import("./../../../src/pages/mintrakete.tsx" /* webpackChunkName: "component---src-pages-mintrakete-tsx" */),
  "component---src-pages-mintvernetzt-informiert-tsx": () => import("./../../../src/pages/mintvernetzt-informiert.tsx" /* webpackChunkName: "component---src-pages-mintvernetzt-informiert-tsx" */),
  "component---src-pages-news-submission-tsx": () => import("./../../../src/pages/news-submission.tsx" /* webpackChunkName: "component---src-pages-news-submission-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-community-platform-tsx": () => import("./../../../src/pages/privacy-policy-community-platform.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-community-platform-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-mint-buendnis-index-tsx": () => import("./../../../src/pages/projects/mint-buendnis/index.tsx" /* webpackChunkName: "component---src-pages-projects-mint-buendnis-index-tsx" */),
  "component---src-pages-projects-mint-buendnis-list-tsx": () => import("./../../../src/pages/projects/mint-buendnis/list.tsx" /* webpackChunkName: "component---src-pages-projects-mint-buendnis-list-tsx" */),
  "component---src-pages-projects-mint-buendnis-submission-tsx": () => import("./../../../src/pages/projects/mint-buendnis/submission.tsx" /* webpackChunkName: "component---src-pages-projects-mint-buendnis-submission-tsx" */),
  "component---src-pages-projects-mint-raketen-tsx": () => import("./../../../src/pages/projects/mint-raketen.tsx" /* webpackChunkName: "component---src-pages-projects-mint-raketen-tsx" */),
  "component---src-pages-projects-plattform-tsx": () => import("./../../../src/pages/projects/plattform.tsx" /* webpackChunkName: "component---src-pages-projects-plattform-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-terms-of-use-community-platform-tsx": () => import("./../../../src/pages/terms-of-use-community-platform.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-community-platform-tsx" */),
  "component---src-pages-topics-didaktik-tsx": () => import("./../../../src/pages/topics/didaktik.tsx" /* webpackChunkName: "component---src-pages-topics-didaktik-tsx" */),
  "component---src-pages-topics-diversitaet-glossar-tsx": () => import("./../../../src/pages/topics/diversitaet_glossar.tsx" /* webpackChunkName: "component---src-pages-topics-diversitaet-glossar-tsx" */),
  "component---src-pages-topics-diversitaet-tsx": () => import("./../../../src/pages/topics/diversitaet.tsx" /* webpackChunkName: "component---src-pages-topics-diversitaet-tsx" */),
  "component---src-pages-topics-gender-sensible-mint-bildung-tsx": () => import("./../../../src/pages/topics/gender-sensible-mint-bildung.tsx" /* webpackChunkName: "component---src-pages-topics-gender-sensible-mint-bildung-tsx" */),
  "component---src-pages-topics-mint-plus-themenspezial-artikel-mint-plus-lernen-im-kontext-tsx": () => import("./../../../src/pages/topics/mint-plus/themenspezial-artikel-mint-plus-lernen-im-kontext.tsx" /* webpackChunkName: "component---src-pages-topics-mint-plus-themenspezial-artikel-mint-plus-lernen-im-kontext-tsx" */),
  "component---src-pages-topics-mint-plus-tsx": () => import("./../../../src/pages/topics/mint-plus.tsx" /* webpackChunkName: "component---src-pages-topics-mint-plus-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/Partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/Project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

